import React, {useState, useEffect} from 'react';
import {format, addDays} from 'date-fns'
import './index.scss';

const _uuid = () => {
  var d = Date.now();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

const useUser = (userId) => {
	const [isLoading, setIsLoading] = useState(false)
	const [saveSucc, setSaveSucc] = useState(null)
	const [succMsg, setSuccMsg] = useState(null)
	const [errorMsg, setErrorMsg] = useState(null)
	const [user, setUser] = useState({
		name: null,
		email: null,
		token: _uuid(),
		fromDatetime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		toDatetime: format(addDays(new Date(), 1), 'yyyy-MM-dd 23:59:59'),
	})

	return {
		user,
		isLoading,
		saveSucc,
		succMsg,
		errorMsg,
		setUser: (k, v) => {
			let newUser = {...user}
			newUser[k] = v
			setUser(newUser)
		},
		save: () => {
			setIsLoading(true)
			setErrorMsg(null)
			setSaveSucc(null)
			setSuccMsg(null)

			fetch(window.CONFS.apiServer+'/v1.0/user', {
				body: JSON.stringify(user), // must match 'Content-Type' header
				headers: {
					'content-type': 'application/json'
				},
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				mode: 'cors' // no-cors, cors, *same-origin
			})
				.then((response) => {
					return response.json();
				}).then((response) => {
					console.log(response);
					setIsLoading(false)

					if (response.status==="OK") {
						setSaveSucc(true)

						setSuccMsg(
`請參照安裝和使用說明文件：http://bit.ly/2t1ejgv
用這組序號，填到框框理按確定：${user.token}

試用時間到 ${user.toDatetime}，試用期間沒有任何限制，可以無限制使用。

購買請直接轉帳至台新銀行(812) 帳號是28881001765626 ，轉帳後跟我講一聲就能直接幫你開通了。價格方案是：
* 3 天：$200
* 30 天：$1490
* 終身版本：$5990`
						)
					} else {
						setSaveSucc(false)
						setErrorMsg(response.message)
					}
				}).catch((err) => {
					console.log('catch!')
					setSaveSucc(false)
					setErrorMsg(""+err)
			})
		}
	}
}

/**
 * This DOM hides its self after 2 seconds.
 * And display if its child has changed
 * @param {*} props
 */
const ExpiredDom = (props) => {
	const [isVisiable, setVisitable] = useState(false)

	useEffect(() => {
		setVisitable(true)

		const timer = setTimeout(() => {
			setVisitable(false)
		}, 2000);
		return () => clearTimeout(timer);
	}, [props.children]);

	return (<div>
		{isVisiable ? <div>{props.children}</div> : <div></div>}
	</div>)
}

export default () => {
	const {user, isLoading, saveSucc, succMsg, errorMsg, setUser, save} = useUser()

	return (
		<div className="create-user-app">
			<h2>Create a user</h2>

			<form>
				<div className="row">
					<div className="six columns">
						<label htmlFor="user-name">User Name</label>
						<input className="u-full-width" type="text" placeholder="-" id="user-name"
							value={user.name || ''} onChange={e => setUser("name", e.target.value)}/>
					</div>
					<div className="six columns">
						<label htmlFor="user-email">Email</label>
						<input className="u-full-width" type="email" placeholder="-" id="user-email"
							value={user.email || ''} onChange={e => setUser("email", e.target.value)}/>
					</div>
				</div>

				<div className="row">
					<div className="six columns">
						<label htmlFor="user-token">token</label>
						<input className="u-full-width" type="text" placeholder="-" id="user-token"
							value={user.token}  onChange={e => setUser("token", e.target.value)}/>
					</div>
				</div>

				<div className="row">
					<div className="twelve columns">
						<label htmlFor="">Period</label>
						<input type="text" name="from-datetime" id="from-datetime"
							value={user.fromDatetime} onChange={e => setUser("fromDatetime", e.target.value)}/> ~
						<input type="text" name="to-datetime" id="to-datetime"
							value={user.toDatetime} onChange={e => setUser("toDatetime", e.target.value)}/>
					</div>
				</div>

				{isLoading ?
					<button className="disable">Saving</button>
					:
					<input className="button-primary" type="button" value="Submit" onClick={save}/>
				}

				<div>
					<pre>
						{succMsg && <div className="succ-msg">{succMsg}</div>}
					</pre>

				</div>

				<ExpiredDom>
					{errorMsg && <div className="error-msg">{errorMsg}</div>}
				</ExpiredDom>
			</form>
		</div>
	);
}