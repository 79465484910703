import React from 'react';
import './skeleton/normalize.css';
import './skeleton/skeleton.css';
import './App.css';

import UserCreateApp from './users/create'
import UserTopupApp from './users/topup/topup'

function App() {
  return (
    <div className="container">
      <UserCreateApp />
      <UserTopupApp />
    </div>
  );
}

export default App;
