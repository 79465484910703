import React, {useState, useEffect} from 'react';
import {format, parse, addDays} from 'date-fns'
import './topup.scss';

const useUser = (userId) => {
	const [isLoading, setIsLoading] = useState(false)
	const [saveSucc, setSaveSucc] = useState(null)
	const [succMsg, setSuccMsg] = useState(null)
	const [errorMsg, setErrorMsg] = useState(null)
	const [user, setUser] = useState({
		name: null,
		email: null,
		token: ``,
		fromDatetime: null,
		toDatetime: null,
	})

	return {
		user,
		isLoading,
		saveSucc,
		succMsg,
		errorMsg,
		setUser: (k, v) => {
			let newUser = {...user}
			newUser[k] = v
			setUser(newUser)
		},
		findUser: (token) => {
			setIsLoading(true)
			setErrorMsg(null)
			setSaveSucc(null)
			setSuccMsg(null)

			fetch(window.CONFS.apiServer+`/v1.0/user/find?token=${token}`, {
				headers: {
					'content-type': 'application/json'
				},
				method: 'GET', // *GET, POST, PUT, DELETE, etc.
				mode: 'cors' // no-cors, cors, *same-origin
			})
			.then(response => response.json())
			.then(response => {
				console.log(response)
				setIsLoading(false)

				if (response.status==="OK" && response.user!==null) {
					console.log('status OK')
					setUser({
						name: response.user.name,
						email: response.user.email,
						token: response.user.token,
						fromDatetime: response.user.created_at,
						toDatetime: response.user.expired_at
					})
				} else {
					setUser({
						name: null,
						email: null,
						token: null,
						fromDatetime: null,
						toDatetime: null
					})
				}
			})
			.catch((err) => {
				console.log('catch!')
				setSaveSucc(false)
				setErrorMsg(""+err)
			})
		},
		topupToDate: (date, days) => {
			setIsLoading(true)
			setErrorMsg(null)
			setSaveSucc(null)
			setSuccMsg(null)

			fetch(window.CONFS.apiServer+'/v1.0/user', {
				body: JSON.stringify({
					token: user.token,
					toDatetime: format(date, "yyyy-MM-dd 23:59:59")
				}), // must match 'Content-Type' header
				headers: {
					'content-type': 'application/json'
				},
				method: 'PUT', // *GET, POST, PUT, DELETE, etc.
				mode: 'cors' // no-cors, cors, *same-origin
			})
			.then((response) => {
				return response.json();
			}).then((response) => {
				setIsLoading(false)

				if (response.status==="OK" && response.user!==null) {
					console.log('status OK')
					setUser({
						name: response.user.name,
						email: response.user.email,
						token: response.user.token,
						fromDatetime: response.user.created_at,
						toDatetime: response.user.expired_at
					})

					setSuccMsg(
						`$ ${response.user.name} ${format(new Date, "yyyy/MM/dd")} 購買 ${days} 天，用到 ${response.user.expired_at}`+
						`\n\n`+
						`好囉，已經開通 ${days} 天，可以用到 ${response.user.expired_at}。序號請一樣使用 ${response.user.token}`)
				}
			}).catch((err) => {
				console.log('catch!')
				setSaveSucc(false)
				setErrorMsg(""+err)
			})
		}
	}
}

/**
 * This DOM hides its self after 2 seconds.
 * And display if its child has changed
 * @param {*} props
 */
const ExpiredDom = (props) => {
	const [isVisiable, setVisitable] = useState(false)

	useEffect(() => {
		setVisitable(true)

		const timer = setTimeout(() => {
			setVisitable(false)
		}, 2000);
		return () => clearTimeout(timer);
	}, [props.children]);

	return (<div>
		{isVisiable ? <div>{props.children}</div> : <div></div>}
	</div>)
}

export default () => {
	const {user, findUser, isLoading, saveSucc, succMsg, errorMsg, setUser, topupToDate} = useUser()
	const [days, setDays] = useState(33)
	let newTopupToDate = null

	if (user.toDatetime && days!=="") {
		let now = new Date()
		let expiredDate = parse(user.toDatetime, 'yyyy-MM-dd HH:mm:ss', new Date())
		if (expiredDate<now) {
			expiredDate = now
		}
		newTopupToDate = addDays(expiredDate, parseInt(days, 10))
	}

	return (
		<div className="create-user-app">
			<h2>Topup</h2>

			<form>
				<div className="row">
					<div className="six columns">
						<label htmlFor="user-token">token</label>
						<input className="u-full-width" type="text" placeholder="-" id="user-token"
							value={user.token || ""}  onChange={e => setUser("token", e.target.value)}/>
					</div>
					<div className="six columns">
						<label htmlFor="user-token">&nbsp;</label>
						<input className="button-primary" type="button" value="Find" onClick={e => findUser(user.token)}/>
					</div>
				</div>

				{user.toDatetime && 
					<div className="row">
						<div className="twelve columns">
							{user.name} {user.email} <br />
							{user.token} <br />
							{user.fromDatetime} ~ {user.toDatetime}
						</div>
					</div>
				}

				{user.toDatetime && 
					<div className="row">
						<div className="twelve columns">
							Add &nbsp;

							<input className="" type="number" placeholder="days" value={days}  onChange={e => setDays(e.target.value)} />

							&nbsp; Days &nbsp;

							<input className="button-primary" type="button" 
								value={`Topup to ${newTopupToDate ? format(newTopupToDate, "yyyy-MM-dd") : "?"}`} 
								onClick={() => topupToDate(newTopupToDate, days)}/>
						</div>
					</div>

				}

				{isLoading &&
					<div>Saving</div>
				}

				<div>
					<pre>
						{succMsg && <div className="succ-msg">{succMsg}</div>}
					</pre>

				</div>

				<ExpiredDom>
					{errorMsg && <div className="error-msg">{errorMsg}</div>}
				</ExpiredDom>
			</form>
		</div>
	);
}